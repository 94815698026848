import {
   stage
} from "../../js/path";

export default {
   data() {
      return {
         noData: false,
         showData: false,
         table_header: [],
         params: "",
         key: 0,
         activeTab: "all",
         pageNo: null,
         fields: [{
               key: "id",
               label: "ID",
               sortable: true,
            },
            {
               key: "name",
               label: "Stage Name",
            },
            {
               key: "is_active",
               label: "Status"
            },
            {
               key: "edit",
            },
            {
               key: "delete",
            }
         ],
         tableData: [],
         currentPage: 1,
         filter: null,
         filterOn: [],
         sortBy: "id",
         sortDesc: true,
         form: {
            id: "",
            name: '',
            is_active: 0,
         },
      }
   },
   methods: {
      onFiltered(filteredItems) {
         this.totalRows = filteredItems.length;
         this.currentPage = 1;
      },
      searchFor() {
         if (this.filter.length > 1) this.fetchData(this.activeTab);
         else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      filterPage() {
         if (this.pageNo.length > 0) {
            this.params = `&page=${this.pageNo}`;
            this.fetchData(this.activeTab);
            this.currentPage = this.pageNo;
         }
      },
      search(event) {
         if (this.filter.length > 1) {
            if (event.keyCode == 13) {
               this.fetchData(this.activeTab);
            }
         } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      async fetchData(txt) {
         this.showData = false;
         let url = null;
         if (txt == "trash") {
            this.activeTab = txt;
            if (this.filter != null && this.filter != "") {
               url = stage.stageUrl + "?filter=" + txt + "&search=" + this.filter;
            } else {
               url = stage.stageUrl + "?filter=" + txt;
            }
         } else if (txt == "all") {
            this.activeTab = txt;
            if (this.filter != null && this.filter != "") {
               url = stage.stageUrl + "?filter=" + txt + "&search=" + this.filter;
            } else {
               url = stage.stageUrl + "?filter=" + txt;
            }
         }
         url += this.params;
         this.$store.commit("loader/updateStatus", true);
         try {
            const data = await this.getRequest(url);
            this.showData = true;
            if (data.status) {
               const responseData = data.response;
               this.tableData = responseData;
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
         this.key += 1;
      },
      async fetchStage(id) {
         this.$store.commit('loader/updateStatus', true);
         try {
            let url = stage.stageUrl + '/' + id;
            const data = await this.getRequest(url);
            if (data.status) {
               const responseData = data.response;
               if (responseData.name != "" && responseData.name != null) {
                  this.form.name = responseData.name;
               }
               if (responseData.is_active) {
                  this.form.is_active = true;
               } else {
                  this.form.is_active = false;
               }
               if (responseData.id != "" && responseData.id != null) {
                  this.form.id = responseData.id;
               }
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Fetching error!",
            });
         }
         this.$store.commit('loader/updateStatus', false);
      },
      async submitData() {
         this.submitted = true;
         this.$v.$touch();
         if (this.$v.$invalid) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Please Fill The Required Fields"
            });
            return false;
         }
         this.$store.commit("loader/updateStatus", true);
         try {
            let url = stage.stageUrl;
            if (this.$route.name == "edit-stage") {
               url = stage.stageUrl + "/" + this.form.id;
            }
            let dataAppend = new FormData();
            if (this.form.is_active) {
               dataAppend.append("is_active", 1);
            } else {
               dataAppend.append("is_active", 0);
            }
            for (var key in this.form) {
               if (key != "is_active") {
                  dataAppend.append(key, this.form[key])
               }
            }
            if (this.$route.name == "edit-stage") {
               dataAppend.append("_method", "put");
            }
            const data = await this.postRequest(url, dataAppend);
            if (data.status) {
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
               this.$router.push("/stage");
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Fetching error!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      async updateStatus(id) {
         try {
            let index = this.tableData.data.findIndex((e) => e.id === id);
            const url = stage.updateStageStatus + "/" + id;
            const data = await this.postRequest(url, {
               is_active: !this.tableData.data[index].is_active
            });
            if (data.status) {
               this.tableData.data[index] = data.response;
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message
               })
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Fetching error!"
            })
         }
      },
      tabActive() {
         if (this.activeTab == "trash") {
            if (this.table_header.includes("delete")) {
               let index = this.fields.findIndex((item) => item.key == "delete");
               this.fields[index] = {
                  key: "restore",
               };
               let table_index = this.table_header.findIndex(
                  (item) => item == "delete"
               );
               this.table_header[table_index] = "restore";
            } else {
               this.table_header.push("restore");
               this.fields.push({
                  key: "restore",
               });
            }

            if (!this.can("restore-stage")) {
               let index = this.fields.findIndex((item) => item.key == "restore");
               if (index !== -1) {
                  this.fields.splice(index, 1);
                  let table_index = this.table_header.findIndex(
                     (item) => item == "restore"
                  );
                  this.table_header.splice(table_index, 1);
               }
            }

            let index = this.fields.findIndex((item) => item.key == "edit");
            if (index !== -1) {
               this.fields.splice(index, 1);
               let table_index = this.table_header.findIndex(
                  (item) => item == "edit"
               );
               this.table_header.splice(table_index, 1);
            }
         } else {
            if (this.table_header.includes("restore")) {
               if (!this.can("edit-stage")) {
                  this.fields[3] = {
                     key: "delete",
                  };
               } else {
                  this.fields[4] = {
                     key: "delete",
                  };
               }

               let table_index = this.table_header.findIndex(
                  (item) => item == "restore"
               );
               this.table_header[table_index] = "delete";
            }

            if (!this.can("edit-stage")) {
               let index = this.fields.findIndex((item) => item.key == "edit");
               if (index !== -1) {
                  this.fields.splice(index, 1);
                  let table_index = this.table_header.findIndex(
                     (item) => item == "edit"
                  );
                  this.table_header.splice(table_index, 1);
               }
            } else {
               if (!this.table_header.includes("edit")) {
                  this.table_header.push("edit");
                  this.fields[3] = {
                     key: "edit",
                  };
               }
            }
            if (!this.can("delete-stage")) {
               let index = this.fields.findIndex((item) => item.key == "delete");
               if (index !== -1) {
                  this.fields.splice(index, 1);
                  let table_index = this.table_header.findIndex(
                     (item) => item == "delete"
                  );
                  this.table_header.splice(table_index, 1);
               }
            } else {
               if (!this.table_header.includes("delete")) {
                  this.table_header.push("delete");
                  this.fields[4] = {
                     key: "delete",
                  };
               }
            }
         }
      },
      async deleteCampaign(id) {
         this.$store.commit("loader/updateStatus", true);
         try {
            let index = this.tableData.data.findIndex((e) => e.id === id);
            const url = stage.stageUrl + "/" + id;
            const data = await this.postRequest(url, {
               _method: "DELETE",
            });
            if (data.status) {
               this.tableData.data.splice(index, 1);
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
      async restoreCampaign(id) {
         this.$store.commit("loader/updateStatus", true);
         try {
            let index = this.tableData.data.findIndex((e) => e.id === id);
            const url = stage.restoreStage + "/" + id;
            const data = await this.postRequest(url, {});
            if (data.status) {
               this.tableData.data.splice(index, 1);
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "success",
                  title: data.message,
               });
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: err.data ? err.data.message : "Please try again!",
            });
         }
         this.$store.commit("loader/updateStatus", false);
      },
   },
   watch: {
      currentPage: {
         handler: function (value) {
            if (typeof value != undefined) {
               this.params = `&page=${value}`;
               this.fetchData(this.activeTab);
               this.pageNo = this.currentPage;
            }
         },
      },
      activeTab(v) {
         if (v) {
            this.tabActive();
         }
      },
      "form.is_active" (v) {
         if (v) {
            this.form.is_active = true;
         } else {
            this.form.is_active = false;
         }
      }
   },
   created() {
      if (this.$route.name == "add-stage" || this.$route.name == "edit-stage") {
         if (this.$route.name == "edit-stage") {
            this.fetchStage(this.$route.params.id);
         }
      } else if (this.$route.name == "stage") {
         this.fetchData(this.activeTab);
         this.fields.map((item) => {
            this.table_header.push(item.key);
         });
         this.tabActive();
      }
   },
}